.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 57px 84px 60px 84px;
}

.title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 28px;
}

.field {
    margin-top: 12px;
}

.button {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .form {
        width: 90vw;
        padding: 24px 12px 26px 12px;
    }
}
