.container {
    position: relative;
    width: min-content;
}

.label {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.error {
    position: relative;
    max-width: 100%;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: red;
}

.select {
    padding: 14px 18px;
    font-weight: 400;
    font-size: 18px;
    background: #f5f5f5;
    border: 1px solid #333333;
    outline: 2px solid transparent;
    transition: 200ms;
}

.select:hover {
    background: #e6e6e6;
}

.select:focus {
    outline: 2px solid #333333;
}

@media (max-width: 768px) {
    .select {
        padding: 5.5px 18px;
        font-size: 16px;
    }
}
