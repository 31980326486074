.message {
    padding: 48px 64px;
    font-size: 24px;
    width: 560px;
    text-align: center;
}

@media (max-width: 768px) {
    .message {
        padding: 24px 48px;
        font-size: 16px;
        width: 90vw;
    }
}
