.container {
    width: 100%;
    height: 280px;
}

.map {
    width: 100%;
}

.mapY {
    width: 100%;
    height: 100%;
}
