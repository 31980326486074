.activate {
    width: 386px;
    padding: 68px 72px;
}

.info {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-bottom: 34px;
}

.button {
    padding: 15px 40px !important;
}

.success {
    color: green;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
}

.error {
    text-align: center;
    font-size: 18px;
    color: red;
    margin-top: 12px;
    font-weight: 400;
}

@media (max-width: 768px) {
    .activate {
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 68px 19px;
    }
}
