.container {
    position: relative;
    width: min-content;
}

.label {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.error {
    position: relative;
    max-width: 100%;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: red;
}

.input {
    font-size: 18px;
    border-radius: 0;
    background: #f5f5f5;
    padding: 12px 18px;
    border: 1px solid #333333;
    outline: 2px solid transparent;
    font-weight: 400;
    transition: 200ms;
}

.input:hover {
    background: #e6e6e6;
}

.input:focus {
    outline: 2px solid #333333;
}

.input::placeholder {
    color: black;
}

.inputBlue {
    padding: 8px 21px;
    background: white;
    border: 1px solid #467bc5;
    border-radius: 50px;
}

@media (max-width: 768px) {
    .input {
        padding: 4px 18px;
        font-size: 16px;
    }
}
