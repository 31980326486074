.stockCardHover {
    cursor: pointer;
    transition: 200ms;
}

.stockCardHover:hover {
    transform: scale(1.05);
}

.imageBlock {
    position: relative;
}

.image {
    width: 100%;
    height: 217px;
    object-fit: cover;
}

.status {
    width: min-content;
    white-space: nowrap;
    margin: 0 0 10px auto;
    padding: 4px 10px;
    color: white;
}

.statusGreen {
    background: #30c573;
}

.statusOrange {
    background: #ff7f00;
}

.statusGray {
    background: #858585;
}

.skeletonBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.skeleton {
    position: absolute !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0 !important;
}

.discount {
    position: absolute;
    z-index: 2;
    left: 18px;
    bottom: 0;
    transform: translateY(50%);
    padding: 5px 8px;
    background: #ff7f00;
}

.percent {
    font-weight: 900;
    color: white;
}

.description {
    padding: 20px 0 0 18px;
}

@media (max-width: 822px) {
    .image {
        height: calc(27vw);
    }
}

@media (max-width: 565px) {
    .image {
        height: calc(54vw);
    }
}
