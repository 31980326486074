.menu {
    width: 100%;
    background: white;
    border: 1px solid #d1def0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 12px 0;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.menu button {
    width: 100%;
}

.status {
    color: black;
    font-size: 20px;
    font-size: 24px;
    line-height: 29px;
}

@media (max-width: 768px) {
    .status {
        font-size: 16px;
    }
}
