.container {
    position: relative;
}

.label {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.error {
    position: relative;
    max-width: 100%;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: red;
}

.border {
    border: 1px solid gray;
}
