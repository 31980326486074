.container {
    position: relative;
    width: 100%;
}

.label {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.error {
    position: relative;
    max-width: 100%;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: red;
}

.input {
    display: none;
}

.upload {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
    max-width: 100%;
}

.button {
    cursor: pointer;
    color: white;
    background: #04a1fa;
    padding: 8px 16px;
    border-radius: 50px;
    transition: 200ms;
}

.button:hover {
    background: #64c7ff;
    outline: none;
}

@media (max-width: 768px) {
    .upload {
        flex-direction: column;
        align-items: stretch;
        gap: 2px;
    }
    .container {
        margin-bottom: 12px;
    }
}
