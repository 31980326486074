.list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.title {
    font-size: 24px;
    line-height: 29px;
}

.address {
    padding: 30px 40px 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.name {
    line-height: 135%;
    color: #333333;
}

.addressItem {
    display: flex;
    gap: 20px;
}

.itemIcon {
    font-size: 20px;
}

.itemText {
    font-weight: 400;
    font-size: 16px;
}

.link {
    font-weight: 400;
    font-size: 24px;
    line-height: 135%;
    color: #559bfb;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.map {
    margin-top: 28px;
    height: 414px !important;
}

@media (max-width: 1024px) {
    .list {
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }
    .address {
        padding: 15px 20px 15px 0;
    }
}

@media (max-width: 768px) {
    .list {
        grid-template-columns: 1fr;
        gap: 12px;
    }
    .address {
        padding: 15px 20px 15px 0;
    }
}

@media (max-width: 425px) {
    .link {
        font-size: 16px;
    }
    .itemIcon {
        font-size: 16px;
    }
    .itemText {
        font-size: 14px;
    }
    .map {
        height: 320px !important;
    }
}
