.button {
    cursor: pointer;
    padding: 15px 40px;
    background: transparent;
    outline: 2px solid transparent;
    border-radius: 0;
    border: 1px solid #223753;
    transition: 200ms;
}

.button:hover {
    background: #efefef;
    outline: 2px solid #223753;
}

.button:focus {
    background: #efefef;
    outline: 2px solid #dbdbdb;
    border: 1px solid #dbdbdb;
    color: white;
}

.button:disabled {
    cursor: not-allowed;
    color: #223753;
    opacity: 0.3;
}

.button:disabled:hover {
    outline: 2px solid transparent;
    border: 1px solid #223753;
    background: transparent;
}

.buttonBlue {
    background: #04a1fa;
    border-radius: 50px;
    color: white;
    border: 0;
}

.buttonBlue:hover {
    background: #64c7ff;
    outline: none;
}

.buttonBlue:focus {
    border: 0;
    background: #64c7ff;
    outline: 1px solid #04a1fa;
}

.buttonBlue:disabled {
    color: white;
    background: gray;
}

.buttonBlue:disabled:hover {
    background: gray;
}

.buttonOrange {
    background: #ff7f00;
    border-radius: 50px;
    border: 0;
    outline: 2px solid transparent;
}

.buttonOrange:hover {
    background: #ff952d;
    color: white;
    outline: 2px solid transparent;
}

.buttonOrange:focus {
    background: #ffa449;
    border: 0;
    outline: 2px solid #ff7f00;
}

.buttonOrange:disabled:hover {
    color: #223753;
    background: #ff7f00;
    border-radius: 50px;
    border: 0;
    outline: 2px solid transparent;
}

@media (max-width: 768px) {
    .button {
        padding: 5px 10px;
    }
}
