.form {
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
}

@media (max-width: 768px) {
    .form {
        padding: 48px 24px;
    }
}
