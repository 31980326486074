.sidebar {
    width: auto;
    padding: 50px 77px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 8;
    margin-top: 66px;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 28px;
}

@media (max-width: 1024px) {
    .sidebar {
        margin-top: 20px;
        padding: 12px 12px 12px 12px;
    }
    .menu {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }
}

@media (max-width: 768px) {
    .menu button {
        background-color: white !important;
        font-weight: 400;
    }

    .menu button:focus {
        color: black !important;
    }
}
