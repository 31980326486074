.container {
    width: 100%;
    max-width: 1238px;
    margin: 50px 0;
    padding: 0 12px;
}

.imageAndWidgets {
    display: grid;
    grid-template-columns: 48.5% 40.3%;
    gap: 11.2%;
}

.image {
    width: 100%;
    height: auto;
}

.tabs {
    margin: 56px 4px;
}

.content {
    padding: 56px;
    background: white;
    border: 1px solid #c6d8f0;
}

@media (max-width: 768px) {
    .imageAndWidgets {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 24px;
    }
    .content {
        padding: 24px;
    }
    .tabs {
        margin: 18px 4px;
    }
}
