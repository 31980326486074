.container {
    width: 100%;
    max-width: 1238px;
    margin: 50px 0;
    padding: 0 12px;
}

.tableBlock {
    overflow-y: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table thead tr {
    background-color: white;
    color: #223753;
    text-align: left;
}

.table th,
.table td {
    padding: 12px 15px;
}

.table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-child(even) {
    background-color: white !important;
}

.table tbody tr:last-of-type {
    border-bottom: 2px solid white;
}

.table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.password {
    padding: 4px 24px;
    background-color: orange;
    color: white;
}

.delete {
    padding: 4px 24px;
    background-color: red;
    outline: 2px solid transparent !important;
}

.delete:hover {
    background-color: rgb(233, 0, 0);
}

.delete:focus {
    background-color: rgb(233, 0, 0);
    outline: 2px solid rgb(185, 0, 0) !important;
}
