.coupon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.value {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.numberOfCoupons {
    font-weight: 400;
    line-height: 20px;
}

@media (max-width: 768px) {
    .coupon {
        gap: 12px;
    }
    .value {
        font-size: 18px;
    }
    .button {
        width: 100%;
        padding: 8px;
    }
    .numberOfCoupons {
        font-size: 14px;
    }
    .br {
        display: none;
    }
}
