.tabs {
    display: flex;
    align-items: center;
    gap: 56px;
}

.tab {
    cursor: pointer;
    border: none;
    background: transparent;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
}

.tabActive {
    font-weight: 700;
}

@media (max-width: 768px) {
    .tabs {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .tab {
        font-size: 18px;
    }
}
