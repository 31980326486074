.coupon {
    padding: 64px 62px;
    width: 386px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.error {
    width: 100%;
    font-size: 24px;
    text-align: center;
    color: red;
}

.warn {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}

.qrCode {
    width: 234px;
    height: 234px;
}

.numberOfCoupons {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}

@media (max-width: 768px) {
    .coupon {
        padding: 32px 9px 18px 9px;
        width: 90vw;
    }
}
