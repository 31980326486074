.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 10;
    animation: showModal 0.3s;
}

.body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: white;
    border: 1px solid #333333;
    max-height: 90vh;
    overflow: auto;
    animation: showBody 0.3s;
}

.close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    transition: 200ms;
}

.close:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .close {
        width: 24px;
        height: 24px;
    }
}

@keyframes showModal {
    from {
        background: rgba(0, 0, 0, 0);
    }
    to {
        background: rgba(0, 0, 0, 0.9);
    }
}

@keyframes showBody {
    from {
        transform: translateX(-50%) translateY(-50%) scale(0);
    }
    to {
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
}
