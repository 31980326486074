.header {
    position: relative;
    background: white;
    height: 90px;
    z-index: 9;
}

.container {
    width: 100%;
    height: 100%;
    max-width: 1190px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
}

.logo {
    width: 81px;
    height: 68px;
    cursor: pointer;
}

.title {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
}

.title br:first-child {
    display: none;
}
.title br:last-child {
    display: none;
}

.authBlock {
    display: flex;
    align-items: center;
    gap: 30px;
}

.authInfo {
    padding: 8px 20px !important;
}

.email {
    font-size: 14px;
    font-weight: 400;
}

.logout {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: 200ms;
}

.logout:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .header {
        height: 60px;
    }
    .logo {
        width: 40.5px;
        height: 34px;
    }
    .title {
        font-size: 12px;
        text-align: right;
    }
    .title br:first-child {
        display: block;
    }
    .title br:nth-child(2n) {
        display: none;
    }
    .title br:last-child {
        display: block;
    }
    .button {
        display: none;
    }
    .authBlock {
        display: none;
    }
}
