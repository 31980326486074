.container {
    width: 100%;
    max-width: 806px;
    margin: 64px 0;
    padding: 38px 42px;
    background: white;
}

.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.selector {
    margin-top: 32px;
    border: 1px solid #333333;
    padding: 20px;
}

.open {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    border-bottom: 1px dashed black;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status {
    white-space: nowrap;
    margin-top: 24px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: black;
}

.status:before,
.status:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border-bottom: 1px dashed black;
    position: relative;
    transform: translateY(-1px);
}

.list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
}

.stock {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

.stockActive {
    font-weight: 700;
}

.statistics {
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
}

.statisticsItem {
    display: flex;
    align-items: center;
    gap: 36px;
}

.itemName {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

.itemValue {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
}

.skeleton {
    height: 29px;
}

@media (max-width: 768px) {
    .container {
        padding: 19px 21px;
    }
    .selector {
        margin-top: 16px;
    }
    .open {
        font-size: 14px;
    }
    .status {
        font-size: 14px;
    }
    .stock {
        font-size: 14px;
    }
    .statisticsItem {
        gap: 0;
        justify-content: space-between;
    }
    .itemName {
        font-size: 14px;
    }
    .itemValue {
        font-size: 22px;
    }
}
