.body {
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* font-size: 28px; */
}

.title {
    font-size: 86px;
}
