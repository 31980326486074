.addStock {
    width: 100%;
    max-width: 806px;
    margin: 64px 0;
    padding: 30px 30px;
    background: white;
}

.header {
    color: #333333;
    position: relative;
}

.back {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
}

.back:hover {
    text-decoration-line: underline;
}

.backIcon {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.title {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
}

@media (max-width: 768px) {
    .addStock {
        padding: 12px 12px;
    }
    .header {
        padding-top: 24px;
    }
    .back {
        top: 0%;
        transform: none;
    }
}
