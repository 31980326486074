.form {
    margin-top: 92px;
}

.body {
    display: grid;
    gap: 40px;
    grid-template-columns: 53% 1fr;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
}

.fieldContainer {
    width: 100% !important;
}

.field {
    width: 100% !important;
}

.button {
    display: block;
    margin: 48px auto 0 auto;
}

.delete {
    width: 32px;
    height: 32px;
    margin-left: auto;
    cursor: pointer;
    transition: 200ms;
}

.delete:hover {
    transform: scale(1.1);
}

.deleteDisabled {
    opacity: 0.5;
}

.deleteDisabled:hover {
    transform: none;
}

.workingHours {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.workingHours div {
    width: 50% !important;
}

.workingHours input {
    width: 100%;
}

.error {
    text-align: center;
    color: red;
    font-weight: 400;
    margin-top: 4px;
}

@media (max-width: 635px) {
    .body {
        grid-template-columns: 1fr;
    }
    .form {
        margin-top: 48px;
    }
}
