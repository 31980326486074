.stocks {
    margin: 64px 0;
}

.container {
    width: 100%;
    max-width: 822px;
    padding: 0 16px;
}

.body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}

@media (max-width: 565px) {
    .body {
        grid-template-columns: 1fr;
    }
}
